import { Axios } from 'utils/Axios';
import { checkTokenAuth } from 'utils/authUtils';
import { Config } from 'utils/initialConfig';

const token = checkTokenAuth();

export const getPaginateChat = async () => {
  try {
    const URL = '/chats';
    const response = await Axios.get(URL);
    return response?.data;
  } catch (error) {
    return error;
  }
};

export const getChatDetail = async (chatId) => {
  try {
    const URL = `/chats/${chatId}`;
    const response = await Axios.get(URL);
    return response?.data;
  } catch (error) {
    return error;
  }
};

export const deleteItemHistoryChat = async (chatId) => {
  const URL = `${Config?.REACT_APP_BACKEND_URL}/chats/${chatId}`;
  const response = await fetch(URL, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.errors[0].message || 'An error occurred.');
  }

  return response.json();
};

export const chatAddMessage = async (payload) => {
  // eslint-disable-next-line no-useless-catch
  try {
    const URL = `${Config?.REACT_APP_BACKEND_URL}/chats`;
    const response = await Axios.post(URL, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': undefined,
      },
    });
    return response?.data;
  } catch (error) {
    throw error;
  }
};

export const chatCreateMessage = async (payload) => {
  // eslint-disable-next-line no-useless-catch
  try {
    const URL = `${Config?.REACT_APP_BACKEND_URL}/chats/message`;
    const response = await Axios.post(URL, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': undefined,
      },
    });
    return response?.data;
  } catch (error) {
    throw error;
  }
};

export const regenerateMessage = async (payload) => {
  // eslint-disable-next-line no-useless-catch
  try {
    const URL = `${Config?.REACT_APP_BACKEND_URL}/chats/message/regenerate`;
    const response = await Axios.post(URL, payload);
    return response?.data;
  } catch (error) {
    throw error;
  }
};

export const shareMessage = async (payload) => {
  // eslint-disable-next-line no-useless-catch
  try {
    const URL = `${Config?.REACT_APP_BACKEND_URL}/chats/shares`;
    const response = await Axios.post(URL, payload);
    return response?.data;
  } catch (error) {
    throw error;
  }
};

export const getShareMessage = async (tokenUrl) => {
  try {
    const URL = `/chats/shares?token=${tokenUrl}`;
    const response = await Axios.get(URL);
    return response?.data;
  } catch (error) {
    return error;
  }
};

export const cancelOCR = async (chatId) => {
  // eslint-disable-next-line no-useless-catch
  try {
    const URL = `${Config?.REACT_APP_BACKEND_URL}/chats/${chatId}/cancel-ocr`;
    const response = await Axios.put(URL);
    return response?.data;
  } catch (error) {
    throw error;
  }
};
